<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex class="mb-5">
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0" style="color: gray">
              <b>إنجاز المندوب</b>
            </h4>
            
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row-wrap>
              <v-flex xs12 md6 v-if="role != 'Salesman'">
                <v-autocomplete
                  :items="users"
                  v-model="ResponsibleId"
                  :filter="customFilter"
                  color="white"
                  item-text="item"
                  label="اسم المندوب"
                  @change="getAllAchievements"
                  item-value="value"
                  return-object
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <template>
                  <v-flex xs12>
                    <v-dialog
                      ref="dialog2"
                      v-model="modal2"
                      :return-value.sync="date2"
                      persistent
                      width="290px"
                      attach=""
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="تاريخ البداية"
                          prepend-icon="mdi-calendar"
                          :rules="EndDateRules"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        @change="getAllAchievements"
                        v-model="startDate"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal2 = false">
                          إغلاق
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog2.save(date2)">
                          حفظ
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </template>
              </v-flex>
              <v-flex xs12 md6>
                <template>
                  <v-flex xs12 align="center">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="تاريخ النهاية"
                          prepend-icon="mdi-calendar"
                          :rules="EndDateRules"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        @change="getAllAchievements"
                        v-model="endDate"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false"> إغلاق </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(date)">
                          حفظ
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </template>
              </v-flex>
            </v-layout>
            <v-layout row-wrap>
              <v-flex xs12 v-if="showCard">
                <apexchart
                  type="pie"
                  :series="series"
                  width="380"
                  :options="chartOptions"
                ></apexchart>
              </v-flex>
              <v-flex xs12 v-else>
                <span>لا يوجد مهام في هذا التاريخ</span>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "Achievements",

  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      users: [],
      ResponsibleId: { item: "جميع المندوبين", value: "0" },
      EndDateRules: [
        () =>
          this.endDate >= this.startDate ||
          "تاريخ النهاية لا يمكن أن يسبق  تاريخ البداية",
      ],
      modal: false,
      modal2: false,
      date: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["  معنية  ", "  قيد التقدم  ", "  بالإنتظار  ", " منتهية  "],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      newTasks: 0,
      archiveTasks: 0,
      doingTasks: 0,
      waitingTasks: 0,
    };
  },

  computed: {
    series() {
      let series = [0, 0, 0, 0];
      series[0] = this.newTasks;
      series[3] = this.archiveTasks;
      series[1] = this.doingTasks;
      series[2] = this.waitingTasks;
      return series;
    },
    showCard() {
      if (
        this.newTasks == 0 &&
        this.archiveTasks == 0 &&
        this.doingTasks == 0 &&
        this.waitingTasks == 0
      )
        return false;
      else return true;
    },
  },

  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getUsers();
    this.getAllAchievements();
  },

  methods: {
    customFilter(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    async getNewAchievements() {
      let url = null;
      this.newTasks = 0;
      if (this.ResponsibleId.value == 0) {
        url = "target/NewTasksByMonth?start=" + this.startDate + "&end=" + this.endDate;
      } else {
        url =
          "target/NewTasksByMonth?user=" +
          this.ResponsibleId.value +
          "&start=" +
          this.startDate +
          "&end=" +
          this.endDate;
      }
      await this.ApiService.get(url)
        .then((res) => {
          this.newTasks = res.data.responseData;
        })
        .catch(() => {});
    },
    async getArchiveAchievements() {
      this.archiveTasks = 0;
      let url = null;
      if (this.ResponsibleId.value == 0) {
        url =
          "target/ArchiveTasksByMonth?start=" + this.startDate + "&end=" + this.endDate;
      } else {
        url =
          "target/ArchiveTasksByMonth?user=" +
          this.ResponsibleId.value +
          "&start=" +
          this.startDate +
          "&end=" +
          this.endDate;
      }
      await this.ApiService.get(url)
        .then((res) => {
          this.archiveTasks = res.data.responseData;
        })
        .catch(() => {});
    },
    async getDoingAchievements() {
      this.doingTasks = 0;
      let url = null;
      if (this.ResponsibleId.value == 0) {
        url = "target/DoingTasksByMonth?start=" + this.startDate + "&end=" + this.endDate;
      } else {
        url =
          "target/DoingTasksByMonth?user=" +
          this.ResponsibleId.value +
          "&start=" +
          this.startDate +
          "&end=" +
          this.endDate;
      }
      await this.ApiService.get(url)
        .then((res) => {
          this.doingTasks = res.data.responseData;
        })
        .catch(() => {});
    },
    async getWaitingAchievements() {
      this.waitingTasks = 0;
      let url = null;
      if (this.ResponsibleId.value == 0) {
        url =
          "target/WaitingTasksByMonth?start=" + this.startDate + "&end=" + this.endDate;
      } else {
        url =
          "target/WaitingTasksByMonth?user=" +
          this.ResponsibleId.value +
          "&start=" +
          this.startDate +
          "&end=" +
          this.endDate;
      }
      await this.ApiService.get(url)
        .then((res) => {
          this.waitingTasks = res.data.responseData;
        
        })
        .catch(() => {});
    },
    async getUsers() {
      this.users = [{ item: "جميع المندوبين", value: "0" }];
      await this.ApiService.get("target/GetSalesmen")
        .then((res) => {
          var allUsers = res.data.responseData;
          allUsers.forEach((el) => {
            if (el != null && el.deleted == false) {
              this.users.push({ value: el.id, item: el.fullName });
            }
          });
        })
        .catch(() => {});
    },
    getAllAchievements() {
      if (this.endDate < this.startDate) {
        return;
      }
      this.getNewAchievements();
      this.getArchiveAchievements();
      this.getDoingAchievements();
      this.getWaitingAchievements();
    },
    
  }
};
</script>

